import React, { useState } from 'react';

import { useClassnames } from '../../../hooks/use-classnames';
import { TStrapiEntity } from '../../../types/strapi';
import { IDirection } from '../../../types/strapi/vacancies';
import { formatText } from '../../../utils';
import LinkButton from '../../button-like/link-button';
import GridWrapper from '../../grid-wrapper';
import Accordion from '../../TechnologyPage/components/Accordion';

import './index.css';

type TDirection = Pick<TStrapiEntity<IDirection>, 'header' | 'description' | 'id'>;

interface IProps {
    header: string,
    linkButtonText: string | false,
    directions: Array<TDirection>
}

const cssBlock = 'team-vacancies';

export const TeamVacancies: React.FC<IProps> = ({ header, linkButtonText, directions }) => {
    const cn = useClassnames();
    const [openedAccordionId, setOpenedAccordion] = useState<string | null>(null);

    const handleAccordionClick = (accordionId: string) => {
        if(accordionId === openedAccordionId) {
            setOpenedAccordion(null);
        } else {
            setOpenedAccordion(accordionId);
        }
    };

    return (
        <React.Fragment>
            <div className={cn(`${cssBlock}__header`)} dangerouslySetInnerHTML={{ __html: formatText(header) }} />
            <GridWrapper>
                <div className={cn(`${cssBlock}__content`)}>
                    <ul className={cn(`${cssBlock}__directions`)}>
                        {directions.map((direction) => (
                            <Accordion
                                key={direction.id}
                                title={direction.header}
                                description={direction.description}
                                descriptionClassName={cn(`${cssBlock}__direction-description`)}
                                headingSize="m"
                                withBorderTop={true}
                                onClick={handleAccordionClick}
                                isOpened={String(direction.id) === openedAccordionId}
                            >
                                {linkButtonText && (
                                    <LinkButton
                                        isGatsbyLink={true}
                                        size="s"
                                        className={cn(`${cssBlock}__link`)}
                                        href={`/vacancies?direction=${direction.id}`}
                                    >
                                        {linkButtonText}
                                    </LinkButton>
                                )}
                            </Accordion>
                        ))}
                    </ul>
                </div>
            </GridWrapper>
        </React.Fragment>
    );
};
